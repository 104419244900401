 .hero-img {
  background: rgba(253, 195, 20);
  height: 80vh;
  width: 100%;
  position: relative;
 }

 .hero-img:before{
    content:'';
    background-color: rgba(253, 195, 20);
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
 }

 .hero-img .heading{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
 }

 .hero-img h1{
    font-size: 2.4rem;
    color: rgb(0, 0, 0);
 }

 .hero-img p{
    font-size: 1.4rem;
    color: rgb(0, 0, 0);
 }

 @media screen and (max-width:640px)
 {
    .hero-img h1{
        font-size: 2rem;
    }
 }



