.headerStyle {
    background: rgba(0, 0, 0, 0.3);
    height: 80vh;
    width: 100%;
    position: relative;
   }
  
   .headerImage:before{
      content:'';
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: -1;
   }
  
   .headerStyle .heading{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
   }
  
   .headerStyle h1{
      font-size: 2.4rem;
   }
  
   .headerStyle p{
      font-size: 1.4rem;
   }
  
   @media screen and (max-width:640px)
   {
      .headerStyle h1{
          font-size: 2rem;
      }
   }