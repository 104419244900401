.content-container {
    min-height: 100vh; /* Adjust height to fill the screen without overflow */
    max-width: 100%;
    background-color: rgba(253, 195, 20);
    align-items: center;
    justify-content: center;
    display: flex; 
    flex-direction: column; 
    box-sizing: border-box;
    overflow-x: hidden; /* Prevent horizontal overflow */
    overflow-y: auto; /* Added to allow vertical scrolling within the container */
}

.content-container .top-section {
    padding-bottom: 0rem;
}

.content-container .top-section .typewriter { 
    padding-top: 2rem;
    display: flex; 
    flex-direction: column;
    align-items: center; /* Changed to center align */
    width: 80vw;
    max-width: 100%; /* Ensure the typewriter doesn't exceed 100% width */
    height: auto; /* Allow height to adjust automatically */
    font-size: 3rem;
    font-family: 'Courier New', Courier, monospace;
    border: none;
    flex-wrap: wrap;
    white-space: normal;
}

.mouse {
    margin: 0 auto;
    width: 3rem;
    height: 5.4rem;
    border: 0.3rem solid #000000;
    border-radius: 6rem;
    position: relative;
}

.mouse::before {
    content: '';
    width: 0.9rem;
    height: 0.9rem;
    position: absolute;
    top: 1rem;
    left: 50%;
    transform: translateX(-50%);
    background-color: #000000;
    border-radius: 50%;
    opacity: 1;
    -webkit-animation: wheel 2s infinite;
    animation: wheel 2s infinite;
}

@keyframes wheel {
    to {
        opacity: 0;
        top: 60px;
    }
}

.heading-home {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 1rem;
    font-size: 1.5rem;
}

.buttons {
    flex-direction: row;
    padding: 1rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    align-items: center;
}

.btn-home {
    padding: 1rem;
    font-size: 1.2rem;
    text-transform: uppercase;
    background: transparent;
    color: #000000;
    border: 2px solid #000000;
    cursor: pointer;
    border-radius: 1.2rem;
    margin-top: 1rem;
    margin-bottom: 1rem;
    margin: 1rem;
}

@media screen and (max-width: 640px) {
    .buttons {
        flex-direction: column;
    }

    .typewriter {
        flex-direction: column;
        font-size: 2rem; /* Decrease font size for smaller screens */
        width: 90vw; /* Adjust width for smaller screens */
    }
}
