@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h1, h4, p, a, h2, title{
  color: #000000;
  display: flex;

}

body {
  margin: 0;
  font-family: 'Outfit', 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background: #000;

  ul{
    list-style: none;
  }

  a{
    text-decoration: none;
  
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.btn{
  padding: 12px 32px;
  font-size: 1.2rem;
  text-transform: uppercase;
  background: transparent;
  color: #fff;
  border: 1px solid #fff;
  cursor: pointer;
}

.btn.btn-light{
  background: rgba(255, 255, 255, 0.2);
}

.btn:hover {
  background: rgba(255, 255, 255, 0.2);
}


