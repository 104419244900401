.header{
    position: fixed;
    width: 100%;
    height: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    z-index: 10;
}

.header-bg{
    background-color: rgba(253, 195, 20, 0.85);
    transition: 0.5s;

}

.nav-menu{
    display: flex;
}

.nav-menu li{
    padding: 0 1rem;
}

.nav-menu a{
    font-size: 1.2rem;
    font-weight: 500;
}


.hamburger{
    display: none;
    color: black;
}

@media screen and (max-width: 1240px){
    .hamburger{
        display: block;
        color: black !important;

    }
    
    .nav-menu{
        position: absolute;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        top: 0;
        left: -100%; 
        text-align: center;
        width: 100%;
        height: 100vh;
        transition: 0.3s;
        z-index: -1;
        background-color: rgba(253, 195, 20, 0.85);

    }

    .nav-menu.active{
        left: 0;
    }

    .nav-menu li{
        padding: 1rem 0;
    }

    .nav-menu a{
        font-size: 2rem;
    }

    
}

