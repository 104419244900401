
#video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: fixed;
  z-index: -1;
  display: flex;
}

.hero{
    height: 100vh;
    width: 100%;
    object-fit: contain;
}

.hero .content{
    text-align: center;
    position: absolute;
    margin: auto;
    width: 100%;
    height: 40%;
    top: 40vh;
    display: flex;
    flex-direction: column;
}

.hero .content .buttons{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

}

.hero .content h1{
    font-size: 4rem;
    color: rgb(0, 0, 0);
}

.hero p{
    font-size: 1.8rem;
    font-weight: 200;
    text-transform: uppercase;
    margin-bottom: 1.6rem;
    color:rgb(0, 0, 0);
   
}



.hero .btn{
    margin: 1rem .2rem;
    border-radius: 20px;
    border-color: rgb(0, 0, 0) ;
    color: rgb(0, 0, 0);
    
}

@media screen and (max-width:640px){
    .content h1{
        font-size: 3rem;
    }

    .content .content .buttons{
        flex-direction: column;
    }

    .content p{
        font-size: 1.4rem;
        margin-bottom: 1.6rem;
    }
}















