
.calendar-container {
    height: 100vh;
    background-color: rgb(0, 0, 0);
    display: flex;
    justify-content: center;
    align-items: center;
}

.calendar-container .calendar {
    display: block;
    background-color: rgb(0, 0, 0);
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-image: url('../assets/blackAndYellowSquare.svg');
}


